import { MixtureListItemDto } from '../../dtos/mixture-list-item/mixture-list-item.dto';
import { MixtureListItemModel } from '../../models/mixture-list-item/mixture-list-item.model';

export class MixtureListItemCreator {
  public static create(dto: MixtureListItemDto): MixtureListItemModel {
    return {
      colorCode: dto.color_code,
      colorName: dto.color_name,
      createdAt: dto.created_at,
      id: dto.id,
      thumbnail: {
        hexColor: dto.thumbnail.hex_color,
        icon: dto.thumbnail.icon,
      },
      combinationName: dto.combination_name,
      isLinkedColor: dto.is_linked_color,
      alternative: dto.alternative,
      brand: dto.brand,
      colorSystem: dto.color_system,
      correctionProcess: {
        state: dto.correction_process.state,
        correctionProcessGroupId: dto.correction_process.correction_process_group_id,
        correctionProcessId: dto.correction_process.correction_process_id,
        displayValue: dto.correction_process.display_value,
        measurementId: dto.correction_process.measurement_id,
      },
      weightedAmount: dto.weighted_amount,
    };
  }
}
