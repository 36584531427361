<div
  class="ingredient-name"
  [ngClass]="{
    'is-withdraw': ingredient.isComponentWithdraw,
    'is-thinner-or-hardener': ingredient.isThinner || ingredient.isHardener,
  }"
>
  <span>{{ ingredient.name }}</span>

  <app-ingredient-tooltips
    [isPowder]="ingredient.isPowder"
    [isWithdraw]="ingredient.isComponentWithdraw"
    [isSpecialIngredient]="ingredient.isSpecialIngredient"
    [isHardener]="ingredient.isHardener"
    [isThinner]="ingredient.isThinner"
  ></app-ingredient-tooltips>
</div>
