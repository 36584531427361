import { RecipeFormulaDetailsDto } from '../../../recipes/dtos/recipe-formula-details/recipe-formula-details.dto';
import { RecipeFormulaDetailsModel } from '../../models/recipe-formula-details/recipe-formula-details.model';
import { RecipeHistoryDto } from '../../../recipes/dtos/recipe-history/recipe-history.dto';
import { RecipeHistoryModel } from '../../../recipes/models/recipe-history/recipe-history.model';
import { RecipeFormulaDetailsAlertDto } from '../../../recipes/dtos/recipe-formula-details-alert/recipe-formula-details-alert.dto';
import { RecipeFormulaDetailsAlertModel } from '../../models/recipe-formula-details-alert/recipe-formula-details-alert.model';
import { RecipeFormulaDetailsAlertCreator } from '../recipe-formula-details-alert/recipe-formula-details-alert.creator';

export class RecipeFormulaDetailsCreator {
  public static create(dto: RecipeFormulaDetailsDto): RecipeFormulaDetailsModel {
    return {
      ...(dto.name && { name: dto.name }),
      brand: dto.brand || '',
      productionYear: dto.production_year || '',
      alternativeDescription: dto.alternative_description || '',
      additionalInformation: dto.additional_information || '',
      colorSystem: dto.color_system,
      producerColorName: dto.producer_color_name || '',
      producerColorCode: dto.producer_color_code || '',
      recipeDate: dto.recipe_date,
      effect: dto.effect || '',
      effectThickness: dto.effect_thickness || '',
      colorBoxes: dto.color_boxes || [],
      primerColor: {
        id: dto.primer_color.id,
        hexColor: dto.primer_color.hex_color,
      },
      recipeHistory: RecipeFormulaDetailsCreator.createRecipeHistory(dto.recipe_history),
      id: dto.id,
      ...(dto.additional_data && {
        additionalData: {
          colorboxId: dto.additional_data.colorbox_id,
          colorSystemId: dto.additional_data.color_system_id,
          flashcard: dto.additional_data.flashcard,
        },
      }),
      score: dto.score || '',
      shape: dto.shape || '',
      ...(dto.overall_fitability && {
        overallFitability: {
          hexColor: dto.overall_fitability.hex_color,
          value: dto.overall_fitability.value,
        },
      }),
      alerts: RecipeFormulaDetailsCreator.createAlerts(dto.alerts),
    };
  }

  private static createRecipeHistory(recipeHistoryDto?: Array<RecipeHistoryDto>): Array<RecipeHistoryModel> {
    if (!recipeHistoryDto) {
      return [];
    }

    return recipeHistoryDto.map((recipeHistoryDto: RecipeHistoryDto) => {
      return {
        formulaId: recipeHistoryDto.formula_id,
        label: recipeHistoryDto.label,
        selected: recipeHistoryDto.selected,
      };
    });
  }

  private static createAlerts(alerts: Array<RecipeFormulaDetailsAlertDto>): Array<RecipeFormulaDetailsAlertModel> {
    return (alerts || []).map((alert: RecipeFormulaDetailsAlertDto) => RecipeFormulaDetailsAlertCreator.create(alert));
  }
}
