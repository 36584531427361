import { Component, EventEmitter, Input, Output } from '@angular/core';

import { RecipeDetailsAnglesDataModel } from '../../models/recipe-details-angles-data/recipe-details-angles-data.model';
import { RecipeDetailsGeometryModel } from '../../models/recipe-details-geometry/recipe-details-geometry.model';
import { RecipeDetailsAngleModel } from '../../models/recipe-details-angles/recipe-details-angle.model';
import { RecipeDetailsAngleTypeEnum } from '../../enums/recipe-details-angle-type/recipe-details-angle-type.enum';

@Component({
  selector: 'app-recipe-details-angles',
  templateUrl: './recipe-details-angles.component.html',
  styleUrls: ['./recipe-details-angles.component.scss'],
  standalone: false,
})
export class RecipeDetailsAnglesComponent {
  @Input() public set anglesData(data: RecipeDetailsAnglesDataModel) {
    this.setAnglesData(data);
  }

  @Output() public geometriesChanged: EventEmitter<Array<string>>;

  public angles: Array<RecipeDetailsAngleModel>;
  public anglesIds: Array<string>;
  public geometries: Array<RecipeDetailsGeometryModel>;

  constructor() {
    this.angles = [];
    this.anglesIds = [];
    this.geometries = [];
    this.geometriesChanged = new EventEmitter<Array<string>>();
  }

  public updateGeometries(geometries: Array<string>): void {
    this.geometriesChanged.emit(geometries);
  }

  private setAnglesData(anglesData: RecipeDetailsAnglesDataModel): void {
    this.geometries = anglesData.geometries;
    this.angles = this.getAnglesInCorrectOrder(anglesData.angles);
    this.anglesIds = this.getAngleIds(anglesData.geometries);
  }

  private getAnglesInCorrectOrder(angles: Array<RecipeDetailsAngleModel>): Array<RecipeDetailsAngleModel> {
    return [
      angles.find((angle: RecipeDetailsAngleModel) => angle.type === RecipeDetailsAngleTypeEnum.original),
      angles.find((angle: RecipeDetailsAngleModel) => angle.type === RecipeDetailsAngleTypeEnum.measurement),
      angles.find((angle: RecipeDetailsAngleModel) => angle.type === RecipeDetailsAngleTypeEnum.correction),
    ].filter((el: RecipeDetailsAngleModel | undefined) => !!el);
  }

  private getAngleIds(geometries: Array<RecipeDetailsGeometryModel>): Array<string> {
    const anglesIds: Array<string> = geometries
      .filter(({ checked }: RecipeDetailsGeometryModel) => checked)
      .map(({ label }: RecipeDetailsGeometryModel) => `${label}`);

    if (anglesIds.length === 1) {
      return [...anglesIds, ...anglesIds];
    }

    return anglesIds;
  }
}
