import { Component, EventEmitter, Input, Output } from '@angular/core';

import { RecipeDetailsGeometryModel } from '../../models/recipe-details-geometry/recipe-details-geometry.model';

@Component({
  selector: 'app-recipe-details-measuring-angles-form',
  templateUrl: './recipe-details-measuring-angles-form.component.html',
  styleUrls: ['./recipe-details-measuring-angles-form.component.scss'],
  standalone: false,
})
export class RecipeDetailsMeasuringAnglesFormComponent {
  @Input() public set geometries(measurementAngles: Array<RecipeDetailsGeometryModel>) {
    this.measurementAngles = measurementAngles;

    this.updateIsSelectedCheckboxDisabled();
  }

  @Output() public geometriesChanged: EventEmitter<Array<string>>;

  public measurementAngles: Array<RecipeDetailsGeometryModel>;
  public isSelectedCheckboxDisabled: boolean;

  constructor() {
    this.measurementAngles = [];
    this.isSelectedCheckboxDisabled = false;
    this.geometriesChanged = new EventEmitter<Array<string>>();
  }

  public emitSelectedGeometriesIds(): void {
    this.geometriesChanged.next(this.getSelectedAngleIds());
  }

  private updateIsSelectedCheckboxDisabled(): void {
    queueMicrotask(() => {
      const selectedCount: number = this.getSelectedCheckboxCount();
      this.isSelectedCheckboxDisabled = selectedCount === 1;
    });
  }

  private getSelectedCheckboxCount(): number {
    return this.measurementAngles.filter(({ checked }: RecipeDetailsGeometryModel) => checked).length;
  }

  private getSelectedAngleIds(): Array<string> {
    return this.measurementAngles
      .filter(({ checked }: RecipeDetailsGeometryModel) => checked)
      .map(({ key }: RecipeDetailsGeometryModel) => key);
  }
}
