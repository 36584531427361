import { Component, Input, OnDestroy } from '@angular/core';

import { take } from 'rxjs/operators';

import { SubscriptionStoreComponent } from '../../../shared/components/subscription-store/subscription-store.component';
import { LinkedColorsDetailsModel } from '../../models/linked-colors-details/linked-colors-details.model';
import { LinkedColorAlternativeListItemModel } from '../../models/linked-color-alternative-list-item/linked-color-alternative-list-item.model';
import { RecipeFormulaBasicModel } from '../../../recipe-details/models/recipe-formula-basic/recipe-formula-basic.model';
import { RecipeDetailsModalService } from '../../../recipe-details/services/recipe-details-modal/recipe-details-modal.service';
import { RecipeDetailsModalParamsModel } from '../../../recipe-details/models/recipe-details-modal-params/recipe-details-modal-params.model';

@Component({
  selector: 'app-recipe-linked-colors[linkedColorsDetails]',
  templateUrl: './recipe-linked-colors.component.html',
  styleUrls: ['./recipe-linked-colors.component.scss'],
  standalone: false,
})
export class RecipeLinkedColorsComponent extends SubscriptionStoreComponent implements OnDestroy {
  @Input() public colorSystemId: number | null;
  @Input() public linkedColorsDetails!: LinkedColorsDetailsModel;
  @Input() public recipeId?: string;
  @Input() public repairId?: string;

  constructor(private recipeDetailsModalService: RecipeDetailsModalService) {
    super();

    this.colorSystemId = null;
    this.recipeId = '';
    this.repairId = '';
  }

  public openRecipeDetailsModal(alternative: LinkedColorAlternativeListItemModel): void {
    if (!this.recipeId) {
      return;
    }

    const formulas: Array<RecipeFormulaBasicModel> = [
      {
        id: alternative.formulaId,
        colorSystemId: this.colorSystemId || 0,
      },
    ];

    const recipe: RecipeDetailsModalParamsModel = {
      id: this.recipeId,
      colorId: alternative.colorId,
      formulas,
    };

    this.subscription = this.recipeDetailsModalService.openRecipeDetailsModal(recipe, this.colorSystemId).pipe(take(1)).subscribe();
  }
}
