import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { RecipeFormulaIngredientsModel } from '../../models/recipe-formula-ingredietns/recipe-formula-ingredients.model';
import { DropdownOptionModel } from '../../../forms/models/dropdown-option/dropdown-option.model';
import { RecipeTypeModel } from '../../models/recipe-type/recipe-type.model';
import { RecipeTypeEnum } from '../../../recipes/enums/recipe-type/recipe-type.enum';

@Component({
  selector: 'app-recipe-details-ingredients[formulaIngredients][isCorrection]',
  templateUrl: './recipe-details-ingredients.component.html',
  styleUrls: ['./recipe-details-ingredients.component.scss'],
  standalone: false,
})
export class RecipeDetailsIngredientsComponent implements OnInit {
  @Input() public set formulaIngredients(value: Array<RecipeFormulaIngredientsModel>) {
    this.buildIngredientsLists(value);
  }

  @Input() public isCorrection!: boolean;
  @Input() public novolOrWeightedType?: RecipeTypeModel;

  public ingredientsOptions: Array<DropdownOptionModel<RecipeFormulaIngredientsModel>>;
  public layerSelect: FormControl<RecipeFormulaIngredientsModel>;
  public ingredientsAmountHeading: string;

  constructor(private translateService: TranslateService) {
    this.layerSelect = new FormControl();
    this.ingredientsOptions = [];
    this.ingredientsAmountHeading = 'recipes.recipeDetailsModal.recipeDetailsIngredients.tabs.amount';
  }

  public ngOnInit(): void {
    this.setIngredientsAmountHeading();
  }

  private setIngredientsAmountHeading(): void {
    if (this.isCorrection) {
      if (this.novolOrWeightedType === RecipeTypeEnum.weightedRecipe) {
        this.ingredientsAmountHeading = 'recipes.recipeDetailsModal.recipeDetailsIngredients.tabs.amountWeighted';

        return;
      }

      this.ingredientsAmountHeading = 'recipes.recipeDetailsModal.recipeDetailsIngredients.tabs.amountWithCorrection';

      return;
    }

    this.ingredientsAmountHeading = 'recipes.recipeDetailsModal.recipeDetailsIngredients.tabs.amount';
  }

  private buildIngredientsLists(formulas: Array<RecipeFormulaIngredientsModel>): void {
    this.ingredientsOptions = formulas.map((formula: RecipeFormulaIngredientsModel) => ({
      label: this.translateService.instant('recipes.recipeDetailsModal.detailsInfo.layersLabel', { number: formula.layerNumber }),
      value: formula,
    }));

    if (this.ingredientsOptions.length) {
      this.layerSelect.setValue(this.ingredientsOptions[0].value);
    }
  }
}
