import { Injectable, Type, Injector } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, tap, map, forkJoin, throwError, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import { ModalService } from '../../../modal/services/modal.service';
import { RecipeDetailsBaseModalComponent } from '../../components/recipe-details-base-modal/recipe-details-base-modal.component';
import { RecipeListItemXriteCorrectionModel } from '../../../recipes/models/recipe-list-item-xrite-correction/recipe-list-item-xrite-correction.model';
import { RecipeDetailsColorCombinationsModalComponent } from '../../components/recipe-details-color-combinations-modal/recipe-details-color-combinations-modal.component';
import { RecipeDetailsLinkedColorsModalComponent } from '../../components/recipe-details-linked-colors-modal/recipe-details-linked-colors-modal.component';
import { RecipeDetailsModalComponent } from '../../components/recipe-details-modal/recipe-details-modal.component';
import { ModalSizeEnum } from '../../../modal/enums/modal-size.enum';
import { RecipeDetailsModalParamsModel } from '../../models/recipe-details-modal-params/recipe-details-modal-params.model';
import { RecipeDetailsService } from '../recipe-details/recipe-details.service';
import { RecipeDetailsStateService } from '../recipe-details-state/recipe-details-state.service';
import { RecipeDetailsTabsService } from '../recipe-details-tabs/recipe-details-tabs.service';
import { NoResultsOptionsModel } from '../../../shared/models/no-results/no-results-options.model';
import { RecipeListItemModel } from '../../../recipes/models/recipe-list-item/recipe-list-item.model';
import { RecipeFormulaDetailsModel } from '../../models/recipe-formula-details/recipe-formula-details.model';
import { RecipeFormulaIngredientsModel } from '../../models/recipe-formula-ingredietns/recipe-formula-ingredients.model';
import { ToggleBoxOptionModel } from '../../../forms/models/toggle-box-option/toggle-box-option.model';
import { RecipeTypeModel } from '../../models/recipe-type/recipe-type.model';
import { RecipeTypeEnum } from '../../../recipes/enums/recipe-type/recipe-type.enum';
import { CurrentSettingsService } from '../../../settings/services/current-settings/current-settings.service';
import { PrepareMixtureStateModel } from '../../../mixes/models/prepare-mixture-state/prepare-mixture-state.model';
import { RecipeIngredientsForMixingDataModel } from '../../../recipes/models/recipe-ingredients-for-mixing-data/recipe-ingredients-for-mixing-data.model';
import { RecipeLayerForMixingModel } from '../../../recipes/models/recipe-layer-for-mixing/recipe-layer-for-mixing-model.model';
import { CreateMixtureLayersUtil } from '../../../mixes/utils/create-mixture-layers/create-mixture-layers.util';
import { RecipeDetailsSpectralChartDataModel } from '../../models/recipe-details-spectral-chart-data/recipe-details-spectral-chart-data.model';
import { RecipeDetailsAnglesDataModel } from '../../models/recipe-details-angles-data/recipe-details-angles-data.model';
import { RecipePriceModel } from '../../models/recipe-price/recipe-price.model';
import { LinkedColorsDetailsModel } from '../../../linked-colors/models/linked-colors-details/linked-colors-details.model';
import { CombinationDetailsModel } from '../../../color-combinations/models/combination-details/combination-details.model';
import { MixtureSourceTypeModel } from '../../../mixes/models/mixture-source-type/mixture-source-type.model';
import { MixtureSourceTypeEnum } from '../../../mixes/enums/mixture-source-type/mixture-source-type.enum';
import { RecipeFormulaBasicModel } from '../../models/recipe-formula-basic/recipe-formula-basic.model';
import { DropdownOptionModel } from '../../../forms/models/dropdown-option/dropdown-option.model';
import { SettingsOptionCollectionListItemModel } from '../../../settings/models/settings-option-collection-list-item/settings-option-collection-list-item.model';
import { ColorCombinationsService } from '../../../color-combinations/services/color-combinations/color-combinations.service';
import { LinkedColorsService } from '../../../linked-colors/services/linked-colors/linked-colors.service';
import { RecipePriceFormModel } from '../../../recipes/models/recipe-price-form/recipe-price-form.model';
import { ApiErrorModel } from '../../../api-errors/models/api-error/api-error.model';
import { ToastsService } from '../../../toasts/services/toasts/toasts.service';
import { MixesService } from '../../../mixes/services/mixes/mixes.service';
import { MixtureRecipeHistoryLayerDetailsModel } from '../../../mixes/models/mixture-recipe-history-layer-details/mixture-recipe-history-layer-details.model';
import { PrepareMixtureStateUtil } from '../../../shared/utils/prepare-mixture-state/prepare-mixture-state.util';

@Injectable()
export class RecipeDetailsModalService {
  private modalStack: Array<NgbModalRef> = [];
  private readonly noDataWarning: NoResultsOptionsModel;

  constructor(
    private modalService: ModalService,
    protected ngbModal: NgbModal,
    protected injector: Injector,
    private recipeDetailsService: RecipeDetailsService,
    private currentSettingsService: CurrentSettingsService,
    private recipeDetailsStateService: RecipeDetailsStateService,
    private linkedColorsService: LinkedColorsService,
    private colorCombinationsService: ColorCombinationsService,
    private toastsService: ToastsService,
    private mixesService: MixesService
  ) {
    this.noDataWarning = {
      title: 'recipes.recipeDetailsModal.noRecipe.title',
      iconClass: 'info-circle-gray-icon',
      iconBackgroundClass: 'warning',
      description: 'recipes.recipeDetailsModal.noRecipe.description',
      showButton: false,
    };
  }

  public getRecipeFormulaDetails(): Observable<RecipeFormulaDetailsModel | null> {
    return this.recipeDetailsStateService.getRecipeFormulaDetails();
  }

  public initRecipeFormulaDetails(
    colorId: number,
    formulaId: number,
    recipeType: RecipeTypeModel,
    measurementId?: string,
    mixtureId?: string
  ): Observable<RecipeFormulaDetailsModel | null> {
    if (colorId < 0 || formulaId < 0) {
      return of(null);
    }

    this.recipeDetailsStateService.setIsTabDataError(false);

    return this.recipeDetailsService.getRecipeFormulaDetails(colorId, formulaId, recipeType, measurementId, mixtureId).pipe(
      tap((recipeDetails: RecipeFormulaDetailsModel) => this.recipeDetailsStateService.setRecipeFormulaDetails(recipeDetails)),
      catchError((apiError: ApiErrorModel) => {
        this.toastsService.showError(apiError.message);
        this.recipeDetailsStateService.setIsTabDataError(true);

        return throwError(() => apiError);
      })
    );
  }

  public getNoRecipeSelectedWarning(): NoResultsOptionsModel {
    return this.noDataWarning;
  }

  public getRecipeFormulaIngredients(): Observable<Array<RecipeFormulaIngredientsModel>> {
    return this.recipeDetailsStateService.getRecipeFormulaIngredients();
  }

  public initRecipeFormulaIngredients(
    formulaId: number,
    type: RecipeTypeModel,
    measurementId?: string,
    recipeSignature?: string,
    mixtureId?: string
  ): Observable<void> {
    this.recipeDetailsStateService.setIsTabDataError(false);

    return this.recipeDetailsService.getRecipeIngredients(formulaId, type, measurementId, recipeSignature, mixtureId).pipe(
      tap((recipeFormulaIngredientsData: Array<RecipeFormulaIngredientsModel>) => {
        this.recipeDetailsStateService.setRecipeFormulaIngredients(recipeFormulaIngredientsData);
      }),
      catchError((apiError: ApiErrorModel) => {
        this.toastsService.showError(apiError.message);
        this.recipeDetailsStateService.setIsTabDataError(true);

        return throwError(() => apiError);
      }),
      map(() => void 0)
    );
  }

  public initRecipeFormulaCorrectionIngredients(
    measurementId: string,
    recipeSignature: string,
    type?: RecipeTypeModel,
    mixtureId?: string
  ): Observable<void> {
    this.recipeDetailsStateService.setIsTabDataError(false);

    return this.recipeDetailsService.getRecipeCorrectionIngredients(measurementId, recipeSignature, type, mixtureId).pipe(
      tap((recipeFormulaIngredientsData: Array<RecipeFormulaIngredientsModel>) => {
        this.recipeDetailsStateService.setRecipeFormulaIngredients(recipeFormulaIngredientsData);
      }),
      catchError((apiError: ApiErrorModel) => {
        this.toastsService.showError(apiError.message);
        this.recipeDetailsStateService.setIsTabDataError(true);

        return throwError(() => apiError);
      }),
      map(() => void 0)
    );
  }

  public setRecipeDetails(recipe: RecipeListItemModel | null): void {
    if (!recipe) {
      return;
    }

    this.recipeDetailsStateService.updateRecipeState({
      recipeDetails: recipe,
      colorId: recipe.colorId,
      formulaId: recipe.formulas[0].id,
    });
  }

  public getRecipeTypeOptions(): Array<ToggleBoxOptionModel<RecipeTypeModel>> {
    return [
      {
        label: 'recipes.recipeDetailsModal.recipeTypeOptions.novolRecipe',
        value: RecipeTypeEnum.novolRecipe,
        isDisabled: false,
      },
      {
        label: 'recipes.recipeDetailsModal.recipeTypeOptions.correction',
        value: RecipeTypeEnum.correction,
        isDisabled: true,
      },
    ];
  }

  public getNoColorSystemSelectedWarning(): NoResultsOptionsModel {
    return {
      ...this.noDataWarning,
      title: 'recipes.recipeDetailsModal.noColor.title',
      description: 'recipes.recipeDetailsModal.noColor.description',
    };
  }

  public getNoLinkedColorsWarning(): NoResultsOptionsModel {
    return {
      ...this.noDataWarning,
      title: 'recipes.recipeDetailsModal.noLinkedColors.title',
      description: 'recipes.recipeDetailsModal.noLinkedColors.description',
    };
  }

  public getIsTabDataError(): Observable<boolean> {
    return this.recipeDetailsStateService.getIsTabDataError();
  }

  public getMixtureDetailsToPrepareMixture(
    mixtureId: string,
    measurementId?: string,
    searchFiltersSignature?: string,
    repairId?: string
  ): Observable<PrepareMixtureStateModel> {
    return this.mixesService.getMixtureHistoryLayerDetails(mixtureId).pipe(
      map((details: MixtureRecipeHistoryLayerDetailsModel) =>
        PrepareMixtureStateUtil.getPrepareMixtureStateFromMixtureDetails(details, measurementId, searchFiltersSignature, repairId)
      ),
      catchError((apiError: ApiErrorModel) => {
        this.toastsService.showError(apiError.message);
        this.recipeDetailsStateService.setIsTabDataError(true);

        return throwError(() => apiError);
      })
    );
  }

  public getRecipeDetailsToPrepareMixture(
    recipeType: RecipeTypeModel,
    colorId: number,
    formulaId: number,
    recipeId: string,
    measurementId?: string,
    repairId?: string,
    searchFiltersSignature?: string,
    mixtureId?: string
  ): Observable<PrepareMixtureStateModel> {
    const recipeIngredientsForMixingData: RecipeIngredientsForMixingDataModel = {
      type: recipeType,
      measurementId: measurementId,
      recipeSignature: recipeId,
    };

    return forkJoin([
      this.recipeDetailsService.getRecipeFormulaDetails(colorId, formulaId, recipeType, measurementId, mixtureId),
      this.recipeDetailsService.getRecipeIngredientsForMixing(formulaId, recipeIngredientsForMixingData),
    ]).pipe(
      map(([details, layers]: [RecipeFormulaDetailsModel, Array<RecipeLayerForMixingModel>]) => {
        return {
          createMixture: {
            source: {
              id: details.id,
              type: this.getMixtureSourceType(recipeType),
              searchFiltersSignature: searchFiltersSignature,
              measurementId,
            },
            layers: CreateMixtureLayersUtil.createAddMixtureLayers(layers),
            layersNumber: layers.length,
            ...(repairId && { repairId }),
          },
        };
      }),
      catchError((apiError: ApiErrorModel) => {
        this.toastsService.showError(apiError.message);
        this.recipeDetailsStateService.setIsTabDataError(true);

        return throwError(() => apiError);
      })
    );
  }

  public getRecipeFormulaSpectralChartData(): Observable<RecipeDetailsSpectralChartDataModel | null> {
    return this.recipeDetailsStateService.getRecipeFormulaSpectralChartData();
  }

  public initRecipeFormulaSpectralChartData(
    recipeType: RecipeTypeModel,
    colorId: number,
    formulaId: number,
    measurementId?: string | null,
    geometries?: Array<string>
  ): Observable<void> {
    this.recipeDetailsStateService.setIsTabDataError(false);

    return this.recipeDetailsService.getRecipeSpectralChartData(recipeType, colorId, formulaId, measurementId, geometries).pipe(
      tap((data: RecipeDetailsSpectralChartDataModel) => this.recipeDetailsStateService.setRecipeFormulaSpectralChartData(data)),
      catchError((apiError: ApiErrorModel) => {
        this.toastsService.showError(apiError.message);
        this.recipeDetailsStateService.setIsTabDataError(true);

        return throwError(() => apiError);
      }),
      map(() => void 0)
    );
  }

  public getRecipeFormulaAnglesData(): Observable<RecipeDetailsAnglesDataModel | null> {
    return this.recipeDetailsStateService.getRecipeFormulaAnglesData();
  }

  public initRecipeFormulaAnglesData(
    colorId: number,
    formulaId: number,
    measurementId?: string | null,
    geometries?: Array<string>,
    includesCorrection?: boolean
  ): Observable<void> {
    this.recipeDetailsStateService.setIsTabDataError(false);

    return this.recipeDetailsService.getRecipeAngles(colorId, formulaId, measurementId, geometries, includesCorrection).pipe(
      tap((data: RecipeDetailsAnglesDataModel) => {
        this.recipeDetailsStateService.setRecipeFormulaAnglesData(data);
      }),
      catchError((apiError: ApiErrorModel) => {
        this.toastsService.showError(apiError.message);
        this.recipeDetailsStateService.setIsTabDataError(true);

        return throwError(() => apiError);
      }),
      map(() => void 0)
    );
  }

  public getRecipeFormulaPrices(): Observable<Array<RecipePriceModel>> {
    return this.recipeDetailsStateService.getRecipeFormulaPrices();
  }

  public initRecipeFormulaPrices(
    formulaId: number,
    recipePriceData: RecipePriceFormModel,
    recipeType: RecipeTypeModel,
    measurementId?: string,
    recipeSignature?: string,
    mixtureId?: string
  ): Observable<void> {
    this.recipeDetailsStateService.setIsTabDataError(false);

    return this.recipeDetailsService
      .getRecipePrices(formulaId, recipePriceData, recipeType, measurementId, recipeSignature, mixtureId)
      .pipe(
        tap((prices: Array<RecipePriceModel>) => this.recipeDetailsStateService.setRecipeFormulaPrices(prices)),
        catchError((apiError: ApiErrorModel) => {
          this.toastsService.showError(apiError.message);
          this.recipeDetailsStateService.setIsTabDataError(true);

          return throwError(() => apiError);
        }),
        map(() => void 0)
      );
  }

  public openRecipeDetailsModal(
    recipe: RecipeDetailsModalParamsModel,
    colorSystemId: number | null,
    repairId?: string,
    measurementId?: string,
    isCorrectionStatusShown?: boolean,
    searchFiltersSignature?: string
  ): Observable<void> {
    const currentModal: NgbModalRef = this.modalStack[this.modalStack.length - 1];

    if (currentModal) {
      this.modalService.hideModal();
    }

    const modalComponent: Type<RecipeDetailsBaseModalComponent> = this.getRecipeDetailsModalComponent(recipe);
    const modalRef: NgbModalRef = this.ngbModal.open(modalComponent, {
      animation: false,
      centered: true,
      backdrop: 'static',
      windowClass: `modal ${ModalSizeEnum.large}`,
      keyboard: true,
      injector: Injector.create({
        providers: [
          { provide: RecipeDetailsService, useClass: RecipeDetailsService },
          { provide: RecipeDetailsStateService, useClass: RecipeDetailsStateService },
          { provide: RecipeDetailsTabsService, useClass: RecipeDetailsTabsService },
        ],
        parent: this.injector,
      }),
    });

    if (!modalRef?.componentInstance) {
      return from([]);
    }

    const instance: RecipeDetailsBaseModalComponent = modalRef.componentInstance;
    const formulaId: number = recipe.formulaId || (recipe.formulas?.length && recipe.formulas[0].id) || 0;

    instance.recipeDetailsStateService.resetState();
    instance.recipeDetailsStateService.updateRecipeState({
      recipeDetails: recipe,
      colorId: recipe.colorId,
      formulaId,
    });

    instance.colorSystemId = Number(colorSystemId);
    instance.repairId = repairId;
    instance.measurementId = measurementId;
    instance.selectedRecipe = recipe;
    instance.isCorrectionOptionEnabled = this.getIsCorrectionOptionEnabled(recipe, !!isCorrectionStatusShown);
    instance.isCorrectionStatusShown = !!isCorrectionStatusShown;
    instance.colorId = recipe.colorId;
    instance.formulaId = formulaId;
    instance.searchFiltersSignature = searchFiltersSignature;

    this.modalStack.push(modalRef);

    return from(modalRef.result).pipe(
      tap(() => {
        this.modalStack.pop();

        const previousModal: NgbModalRef = this.modalStack[this.modalStack.length - 1];

        if (!previousModal) {
          return;
        }

        this.modalService.showModal();
      }),
      map(() => void 0)
    );
  }

  public getFormulasColorSystems(formulas: Array<RecipeFormulaBasicModel>): Observable<Array<DropdownOptionModel<number>>> {
    return this.getColorSystemOptions().pipe(
      map((colorSystemOptions: Array<DropdownOptionModel<number>>) => this.getMatchingFormulaColorSystems(colorSystemOptions, formulas))
    );
  }

  public initLinkedColorDetails(
    brandId: number | null,
    colorId: number | null,
    colorSystemId: number | null,
    formulaId: number
  ): Observable<void> {
    this.recipeDetailsStateService.setIsTabDataError(false);

    return this.linkedColorsService.getLinkedColorsDetails(brandId || 0, colorId || 0, colorSystemId || 0, formulaId).pipe(
      tap((details: LinkedColorsDetailsModel) => this.recipeDetailsStateService.setLinkedColorsDetails(details)),
      catchError((apiError: ApiErrorModel) => {
        if (apiError.status !== 404) {
          this.toastsService.showError(apiError.message);
        }

        this.recipeDetailsStateService.setIsTabDataError(true);

        return throwError(() => apiError);
      }),
      map(() => void 0)
    );
  }

  public getLinkedColorData(): Observable<LinkedColorsDetailsModel | null> {
    return this.recipeDetailsStateService.getLinkedColorsDetails();
  }

  public initColorCombinationDetails(brandId: number | null, colorId: number | null, colorSystemId: number | null): Observable<void> {
    this.recipeDetailsStateService.setIsTabDataError(false);

    return this.colorCombinationsService.getCombinationDetails(brandId || 0, colorId || 0, colorSystemId || 0).pipe(
      tap((details: CombinationDetailsModel) => this.recipeDetailsStateService.setColorCombinationDetails(details)),
      catchError((apiError: ApiErrorModel) => {
        this.toastsService.showError(apiError.message);
        this.recipeDetailsStateService.setIsTabDataError(true);

        return throwError(() => apiError);
      }),
      map(() => void 0)
    );
  }

  public getColorCombinationDetails(): Observable<CombinationDetailsModel | null> {
    return this.recipeDetailsStateService.getColorCombinationDetails();
  }

  private getMatchingFormulaColorSystems(
    colorSystemOptions: Array<DropdownOptionModel<number>>,
    formulas: Array<RecipeFormulaBasicModel>
  ): Array<DropdownOptionModel<number>> {
    return colorSystemOptions.filter((option: DropdownOptionModel<number>) =>
      formulas.find((formula: RecipeFormulaBasicModel) => option.value === formula.colorSystemId)
    );
  }

  private getColorSystemOptions(): Observable<Array<DropdownOptionModel<number>>> {
    return this.currentSettingsService.getColorSystemsSettings().pipe(
      map((options: Array<SettingsOptionCollectionListItemModel> | null) =>
        !options
          ? []
          : options.map((elementValue: SettingsOptionCollectionListItemModel) => ({
              value: +elementValue.key,
              label: elementValue.value,
            }))
      ),
      take(1)
    );
  }

  private getMixtureSourceType(recipeType: RecipeTypeModel): MixtureSourceTypeModel {
    if (recipeType === RecipeTypeEnum.correction) {
      return MixtureSourceTypeEnum.correction;
    }

    return MixtureSourceTypeEnum.recipe;
  }

  private getRecipeDetailsModalComponent(recipe: RecipeDetailsModalParamsModel): Type<RecipeDetailsBaseModalComponent> {
    if (recipe.combination?.isCombination) {
      return RecipeDetailsColorCombinationsModalComponent;
    }

    return recipe.linkedColor?.isLinkedColor ? RecipeDetailsLinkedColorsModalComponent : RecipeDetailsModalComponent;
  }

  private getIsCorrectionOptionEnabled(recipe: RecipeDetailsModalParamsModel, canShowCorrectionStatus: boolean): boolean {
    if (!canShowCorrectionStatus) {
      return false;
    }

    const xriteCorrection: RecipeListItemXriteCorrectionModel | null | undefined = recipe?.xriteCorrection;

    return !xriteCorrection?.state.isError && !xriteCorrection?.state.isLoading && !!xriteCorrection?.data?.isCorrectionPerformed;
  }
}
