import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-recipe-details-add-color-issue-button',
  templateUrl: './recipe-details-add-color-issue-button.component.html',
  styleUrls: ['./recipe-details-add-color-issue-button.component.scss'],
  standalone: false,
})
export class RecipeDetailsAddColorIssueButtonComponent {
  @Input() public isDisabled: boolean;

  @Output() public addColorIssueClicked: EventEmitter<void>;

  constructor() {
    this.isDisabled = true;
    this.addColorIssueClicked = new EventEmitter<void>();
  }

  public emitAddColorIssueButtonClick(): void {
    this.addColorIssueClicked.emit();
  }
}
