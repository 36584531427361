import { Component, OnInit } from '@angular/core';

import { filter } from 'rxjs';

import { RecipeDetailsBaseModalComponent } from '../recipe-details-base-modal/recipe-details-base-modal.component';
import { RecipeDetailsTabModel } from '../../models/recipe-details-tab/recipe-details-tab.model';

@Component({
  selector: 'app-recipe-details-modal',
  templateUrl: './recipe-details-modal.component.html',
  styleUrls: ['./recipe-details-modal.component.scss'],
  standalone: false,
})
export class RecipeDetailsModalComponent extends RecipeDetailsBaseModalComponent implements OnInit {
  public override ngOnInit(): void {
    super.ngOnInit();

    this.listenToColorSystemFormChanges();
    this.listenToRecipeTypeFormChanges();
    this.loadTabData(this.selectedTab as RecipeDetailsTabModel);
  }

  private listenToRecipeTypeFormChanges(): void {
    this.subscription = this.recipeTypeForm.valueChanges.pipe(filter(() => !!this.recipeDetails && this.colorSystemId !== null)).subscribe({
      next: () => {
        this.loadTabData(this.selectedTab as RecipeDetailsTabModel);
      },
    });
  }
}
