import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RecipeIngredientsModel } from '../../models/recipe-ingredietns/recipe-ingredients.model';
import { RecipePriceIngredientModel } from '../../models/recipe-price-ingredient/recipe-price-ingredient.model';
import { SharedModule } from '../../../shared/shared.module';

@Component({
  selector: 'app-recipe-ingredient-name[ingredient]',
  templateUrl: './recipe-ingredient-name.component.html',
  styleUrls: ['./recipe-ingredient-name.component.scss'],
  imports: [CommonModule, SharedModule],
})
export class RecipeIngredientNameComponent {
  @Input() public ingredient!: RecipeIngredientsModel | RecipePriceIngredientModel;
}
