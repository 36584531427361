import { RecipeFormulaDetailsAlertDto } from '../../../recipes/dtos/recipe-formula-details-alert/recipe-formula-details-alert.dto';
import { RecipeFormulaDetailsAlertModel } from '../../models/recipe-formula-details-alert/recipe-formula-details-alert.model';

export class RecipeFormulaDetailsAlertCreator {
  public static create(dto: RecipeFormulaDetailsAlertDto): RecipeFormulaDetailsAlertModel {
    return {
      message: dto.message,
      type: dto.type,
    };
  }
}
