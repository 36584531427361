import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { ApiResponseDto } from '../../../core/dtos/api-response/api-response.dto';
import { ApiUrlUtil } from '../../../core/utils/api-url/api-url.util';
import { LinkedColorsDetailsDto } from '../../dtos/linked-colors-details/linked-colors-details.dto';
import { ListDto } from '../../../shared/dtos/list/list.dto';
import { CustomHttpParamEncoder } from '../../../core/encoders/custom-http-params-encoder/custom-http-params.encoder';
import { LinkedColorAlternativeListItemDto } from '../../dtos/linked-color-alternative-list-item/linked-color-alternative-list-item.dto';
import { LinkedColorAlternativesListDataPageDto } from '../../dtos/linked-color-alternatives-list-data-page/linked-color-alternatives-list-data-page.dto';
import { DownloadFileDto } from '../../../shared/dtos/download-file/download-file.dto';
import { DownloadFileDtoCreator } from '../../../shared/creators/download-file-dto/download-file-dto.creator';

@Injectable({
  providedIn: 'root',
})
export class LinkedColorsHttpService {
  constructor(private httpClient: HttpClient) {}

  public getLinkedColorsDetails(
    brandId: number,
    colorId: number,
    colorSystemId: number,
    formulaId: number
  ): Observable<LinkedColorsDetailsDto> {
    return this.httpClient
      .get<
        ApiResponseDto<LinkedColorsDetailsDto>
      >(ApiUrlUtil.getApiUrl(`recipe/linked-colors/${brandId}/${colorId}/${colorSystemId}/${formulaId}/details`))
      .pipe(map((response: ApiResponseDto<LinkedColorsDetailsDto>) => response.data));
  }

  public getLinkedColorAlternativesList(
    id: string,
    colorId: number,
    linkedColorAlternativesListDataPage: LinkedColorAlternativesListDataPageDto
  ): Observable<ListDto<LinkedColorAlternativeListItemDto>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
      fromObject: { ...linkedColorAlternativesListDataPage },
    });

    return this.httpClient
      .get<
        ApiResponseDto<ListDto<LinkedColorAlternativeListItemDto>>
      >(ApiUrlUtil.getApiUrl(`recipe/linked-colors/${id}/${colorId}/alternatives`), { params })
      .pipe(map((response: ApiResponseDto<ListDto<LinkedColorAlternativeListItemDto>>) => response.data));
  }

  public exportLinkedColorToPdf(id: string): Observable<DownloadFileDto> {
    return this.httpClient
      .get<Blob>(ApiUrlUtil.getApiUrl(`recipe/linked-colors/${id}/pdf`), { observe: 'response', responseType: 'blob' as 'json' })
      .pipe(map((response: HttpResponse<Blob>) => DownloadFileDtoCreator.create(response)));
  }
}
