import { Component, Input } from '@angular/core';

import * as Highcharts from 'highcharts';
import { PlotSeriesOptions, SeriesOptionsType, XAxisOptions } from 'highcharts';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  standalone: false,
})
export class LineChartComponent {
  @Input() public set chartOptions(options: Highcharts.Options | null | undefined) {
    if (!options?.series?.length) {
      return;
    }

    this.updateChartOptions(options);
  }

  public Highcharts: typeof Highcharts = Highcharts;
  public options: Highcharts.Options;
  public isUpdate: boolean;

  private categories?: Array<string>;
  private series: Array<SeriesOptionsType>;
  private readonly xAxisLabelsFontWeight: string;
  private readonly yAxisLabelsFontWeight: string;

  constructor() {
    this.options = {};
    this.series = [];
    this.xAxisLabelsFontWeight = '700';
    this.yAxisLabelsFontWeight = '700';

    this.isUpdate = false;
  }

  private updateChartOptions(options: Highcharts.Options): void {
    this.categories = (options.xAxis as XAxisOptions).categories;
    this.series = options.series as Array<SeriesOptionsType>;

    this.options = {
      legend: {
        enabled: false,
      },
      chart: {
        type: 'spline',
        plotBorderWidth: 0,
        plotShadow: false,
        plotBackgroundColor: '#282525',
      },
      plotOptions: {
        spline: {
          enableMouseTracking: false,
        },
        series: {
          lineWidth: 1,
          marker: {
            enabled: false,
          },
          states: {
            hover: {
              marker: {
                enabled: false,
              },
            },
          },
          pointPlacement: 'on',
        } as PlotSeriesOptions,
      },
      title: {
        text: '',
      },
      yAxis: {
        gridLineWidth: 1,
        margin: 0,
        gridLineColor: '#7D7B7B',
        title: {
          text: '',
        },
        labels: {
          style: {
            color: '#282525',
            fontWeight: this.yAxisLabelsFontWeight,
          },
        },
      },
      xAxis: {
        gridLineWidth: 1,
        categories: this.categories,
        gridLineColor: '#7D7B7B',
        type: 'category',
        tickInterval: 2,
        labels: {
          y: 40,
          overflow: 'allow',
          style: {
            color: '#282525',
            fontWeight: this.xAxisLabelsFontWeight,
          },
        },
      },
      credits: {
        enabled: false,
      },
      series: this.series,
    };

    this.isUpdate = true;
  }
}
