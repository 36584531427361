import { Component, Input } from '@angular/core';

import { SubscriptionStoreComponent } from '../../../shared/components/subscription-store/subscription-store.component';
import { LinkedColorsService } from '../../services/linked-colors/linked-colors.service';
import { DownloadFileModel } from '../../../shared/models/download-file/download-file.model';
import { FileUtil } from '../../../shared/utils/file/file.util';

@Component({
  selector: 'app-export-linked-colors-to-pdf',
  templateUrl: './export-linked-colors-to-pdf.component.html',
  styleUrl: './export-linked-colors-to-pdf.component.scss',
  standalone: false,
})
export class ExportLinkedColorsToPdfComponent extends SubscriptionStoreComponent {
  @Input() public id!: string;
  @Input() public isDisabled: boolean;

  constructor(private linkedColorsService: LinkedColorsService) {
    super();

    this.isDisabled = false;
  }

  public exportToPdf(): void {
    this.subscription = this.linkedColorsService.exportLinkedColorToPdf(this.id).subscribe({
      next: (downloadFile: DownloadFileModel) => {
        FileUtil.downloadFile(downloadFile);
      },
    });
  }
}
