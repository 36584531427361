import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Event, NavigationStart, Router } from '@angular/router';

import { Observable, EMPTY, filter, finalize, of } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { switchMap, tap } from 'rxjs/operators';

import { SubscriptionStoreComponent } from '../../../shared/components/subscription-store/subscription-store.component';
import { RecipeDetailsTabModel } from '../../models/recipe-details-tab/recipe-details-tab.model';
import { RecipeDetailsTabsEnum } from '../../enums/recipe-details-tabs/recipe-details-tabs.enum';
import { DropdownOptionModel } from '../../../forms/models/dropdown-option/dropdown-option.model';
import { RecipeFormulaDetailsModel } from '../../models/recipe-formula-details/recipe-formula-details.model';
import { RecipePriceFormModel } from '../../../recipes/models/recipe-price-form/recipe-price-form.model';
import { UserPermissionNameModel } from '../../../user-roles/models/user-permission-name/user-permission-name.model';
import { NoResultsOptionsModel } from '../../../shared/models/no-results/no-results-options.model';
import { ToggleBoxOptionModel } from '../../../forms/models/toggle-box-option/toggle-box-option.model';
import { RecipeTypeModel } from '../../models/recipe-type/recipe-type.model';
import { UserPermissionNameEnum } from '../../../user-roles/enums/user-permission-name/user-permission-name.enum';
import { RecipeDetailsTabsService } from '../../services/recipe-details-tabs/recipe-details-tabs.service';
import { RecipeDetailsStateService } from '../../services/recipe-details-state/recipe-details-state.service';
import { RecipeDetailsNavigationService } from '../../services/recipe-details-navigation/recipe-details-navigation.service';
import { ModalService } from '../../../modal/services/modal.service';
import { CheckRecipeTypeModalComponent } from '../../../recipes/components/check-recipe-type-modal/check-recipe-type-modal.component';
import { ModalSizeEnum } from '../../../modal/enums/modal-size.enum';
import { RecipesFormulaUnitsEnum } from '../../../recipes/enums/recipes-formula-units/recipes-formula-units.enum';
import { RecipesFiltersEnum } from '../../../recipes/enums/recipes-filters/recipes-filters.enum';
import { RecipesFiltersModel } from '../../../recipes/models/recipes-filters/recipes-filters.model';
import { CurrentSettingsService } from '../../../settings/services/current-settings/current-settings.service';
import { RecipeFormulaIngredientsModel } from '../../models/recipe-formula-ingredietns/recipe-formula-ingredients.model';
import { RecipeDetailsSpectralChartDataModel } from '../../models/recipe-details-spectral-chart-data/recipe-details-spectral-chart-data.model';
import { RecipeDetailsAnglesDataModel } from '../../models/recipe-details-angles-data/recipe-details-angles-data.model';
import { RecipePriceModel } from '../../models/recipe-price/recipe-price.model';
import { LinkedColorsDetailsModel } from '../../../linked-colors/models/linked-colors-details/linked-colors-details.model';
import { CombinationDetailsModel } from '../../../color-combinations/models/combination-details/combination-details.model';
import { RecipeDetailsModalParamsModel } from '../../models/recipe-details-modal-params/recipe-details-modal-params.model';
import { RecipeTypeEnum } from '../../../recipes/enums/recipe-type/recipe-type.enum';
import { RecipeDetailsModalService } from '../../services/recipe-details-modal/recipe-details-modal.service';

@Component({
  selector: 'app-recipe-details-base-modal',
  template: '',
  standalone: false,
})
export class RecipeDetailsBaseModalComponent extends SubscriptionStoreComponent implements OnInit, OnDestroy {
  @Input() public measurementId?: string;
  @Input() public repairId?: string;
  @Input() public colorSystemId: number | null = null;
  @Input() public searchFiltersSignature?: string;
  @Input() public mixtureId?: string;

  @Input() public set selectedRecipe(selectedRecipe: RecipeDetailsModalParamsModel) {
    this.recipeDetailsStateService.resetState();
    this.setRecipeDetails(selectedRecipe);
    this.setColorId(selectedRecipe);
    this.initializeColorSystems(selectedRecipe);
    this.initTabs(selectedRecipe);
  }

  @Input() public set isCorrectionStatusShown(isCorrectionStatusShown: boolean) {
    this.setAreAnglesForCorrectionDisplayed(isCorrectionStatusShown);

    if (this.selectedTab?.id === RecipeDetailsTabsEnum.preview) {
      this.tryLoadDataAgain();
    }
  }

  @Input() public set isCorrectionOptionEnabled(isEnabled: boolean) {
    this.isCorrectionPreviousValue = this.isCorrection;

    this.toggleCorrectionOptionState(isEnabled);
    this.refreshRecipeFormulaIngredients();
  }

  public tabs: Array<RecipeDetailsTabModel> = [];
  public isShownRecipeTypeToggle: boolean = false;
  public formulaColorOptions: Array<DropdownOptionModel<number>> = [];
  public isColorSystemFormDisabled: boolean = false;
  public recipeDetails: RecipeDetailsModalParamsModel | null = null;
  public anglesGeometriesIds: Array<string> = ['45as25', '45as45', '45as75'];
  public spectralChartGeometriesIds: Array<string> = ['45as25', '45as45', '45as75'];
  public selectedTab: RecipeDetailsTabModel | null = null;
  public areAnglesForCorrectionDisplayed: boolean = false;
  public isCorrection: boolean = false;
  public colorId: number = 0;
  public formulaId: number = 0;
  public isContentLoading: boolean = false;

  public readonly selectedTabOptions: typeof RecipeDetailsTabsEnum = RecipeDetailsTabsEnum;
  public readonly priceListShowPermission: UserPermissionNameModel = UserPermissionNameEnum.priceListShow;

  public recipePriceForm: RecipePriceFormModel = {} as RecipePriceFormModel;
  public recipeTypeForm: FormControl<RecipeTypeModel> = new FormControl(RecipeTypeEnum.novolRecipe, { nonNullable: true });
  public colorSystemFormControl: FormControl<number | null> = new FormControl<number | null>(null, { validators: Validators.required });
  public recipeTypeOptions: Array<ToggleBoxOptionModel<RecipeTypeModel>> = [];
  public noRecipeSelectedWarning: NoResultsOptionsModel = {} as NoResultsOptionsModel;
  public noColorSystemSelectedWarning: NoResultsOptionsModel = {} as NoResultsOptionsModel;
  public noLinkedColorsWarning: NoResultsOptionsModel = {} as NoResultsOptionsModel;

  public isTabDataError$: Observable<boolean> = of(false);
  public recipeFormulaDetails$: Observable<RecipeFormulaDetailsModel | null> = EMPTY;
  public recipeFormulaIngredients$: Observable<Array<RecipeFormulaIngredientsModel>> = EMPTY;
  public recipeFormulaSpectralChart$: Observable<RecipeDetailsSpectralChartDataModel | null> = EMPTY;
  public recipeFormulaAngles$: Observable<RecipeDetailsAnglesDataModel | null> = EMPTY;
  public recipePriceLists$: Observable<Array<RecipePriceModel>> = EMPTY;
  public linkedColorsDetails$: Observable<LinkedColorsDetailsModel | null> = EMPTY;
  public colorCombinationDetails$: Observable<CombinationDetailsModel | null> = EMPTY;

  protected showTabs: boolean = true;

  private defaultColorboxKey: number = 0;
  private isCorrectionPreviousValue: boolean = false;
  private isInitRecipeDetailsInfoCalled: boolean = false;

  constructor(
    public recipeDetailsTabsService: RecipeDetailsTabsService,
    public activeModal: NgbActiveModal,
    public recipeDetailsModalService: RecipeDetailsModalService,
    public router: Router,
    public route: ActivatedRoute,
    public recipeDetailsStateService: RecipeDetailsStateService,
    public modalService: ModalService,
    private readonly recipeDetailsNavigationService: RecipeDetailsNavigationService,
    protected currentSettingsService: CurrentSettingsService
  ) {
    super();

    this.initializeBaseComponent();
  }

  public ngOnInit(): void {
    this.initRouteChangeListener();
  }

  public override ngOnDestroy(): void {
    this.recipeDetailsStateService.resetState();
    super.ngOnDestroy();
  }

  public close(): void {
    this.activeModal.close();
  }

  public initTabs(recipeDetails: RecipeDetailsModalParamsModel | null): void {
    if (!recipeDetails || !this.showTabs) {
      return;
    }

    this.recipeDetailsTabsService.initTabs(recipeDetails, this?.measurementId);

    if (!this.selectedTab) {
      return;
    }

    this.updateIsShownRecipeTypeToggle(this.selectedTab);
    this.loadTabData(this.selectedTab);
  }

  public onTabButtonClick(tab: RecipeDetailsTabModel): void {
    this.recipeDetailsTabsService.setActiveTab(tab.id || RecipeDetailsTabsEnum.info);
  }

  public setSpectralChartGeometries(geometries: Array<string>): void {
    this.spectralChartGeometriesIds = geometries;

    this.updateFormulaSpectralChart();
  }

  public updateFormulaSpectralChart(): void {
    if (!this.recipeDetails?.id || !this.measurementId) {
      return;
    }

    this.subscription = this.recipeDetailsModalService
      .initRecipeFormulaSpectralChartData(
        this.recipeTypeForm.value,
        this.colorId,
        this.formulaId,
        this.measurementId,
        this.spectralChartGeometriesIds
      )
      .subscribe();
  }

  public setAnglesGeometries(geometries: Array<string>): void {
    this.anglesGeometriesIds = geometries;

    this.updateFormulaAngles();
  }

  public updateFormulaAngles(): void {
    if (!this.recipeDetails?.id || !this.measurementId) {
      return;
    }

    this.subscription = this.recipeDetailsModalService
      .initRecipeFormulaAnglesData(
        this.colorId,
        this.formulaId,
        this.measurementId,
        this.anglesGeometriesIds,
        this.areAnglesForCorrectionDisplayed
      )
      .subscribe();
  }

  public recipePriceFormValueChange(recipePriceForm: RecipePriceFormModel): void {
    this.recipePriceForm = recipePriceForm;

    this.isContentLoading = true;

    this.subscription = this.recipeDetailsModalService
      .initRecipeFormulaPrices(this.formulaId, this.recipePriceForm, this.recipeTypeForm.value, this.measurementId, this.recipeDetails?.id)
      .pipe(
        finalize(() => {
          this.isContentLoading = false;
        })
      )
      .subscribe();
  }

  public addColorIssue(): void {
    if (!this.formulaId) {
      this.subscription = this.recipeDetailsNavigationService
        .createStateAndNavigateToAddColorIssue(this.recipeDetails, this.colorSystemFormControl.value, this.defaultColorboxKey)
        .subscribe({
          next: () => {
            this.modalService.dismissAll();
          },
        });

      return;
    }

    this.subscription = this.recipeDetailsModalService
      .initRecipeFormulaDetails(this.colorId, this.formulaId, this.recipeTypeForm.value, this.measurementId)
      .pipe(
        switchMap((details: RecipeFormulaDetailsModel | null) =>
          this.recipeDetailsNavigationService.createStateFromDetailsAndNavigateToAddColorIssue(details, this.defaultColorboxKey)
        )
      )
      .subscribe({
        next: () => {
          this.modalService.dismissAll();
        },
      });
  }

  public goToPrepareMixturePage(): void {
    if (!this.recipeDetails) {
      return;
    }

    if (!this.showModalBeforeGoingToMixing) {
      this.subscription = this.recipeDetailsNavigationService
        .navigateToPrepareMixturePage(
          this.recipeTypeForm.value,
          this.colorId,
          this.formulaId,
          this.recipeDetails.id,
          this.measurementId,
          this.repairId
        )
        .subscribe({
          next: () => this.modalService.dismissAll(),
        });

      return;
    }

    this.modalService.openModalWithData(CheckRecipeTypeModalComponent, ModalSizeEnum.small, false, {
      colorId: this.colorId,
      formulaId: this.formulaId,
      recipeId: this.recipeDetails.id,
      measurementId: this.measurementId,
      repairId: this.repairId,
      searchFiltersSignature: this.searchFiltersSignature,
      mixtureId: this.mixtureId,
    });
  }

  public initializeBaseComponent(): void {
    this.recipePriceForm = this.getDefaultRecipePriceForm();
    this.recipeTypeOptions = this.recipeDetailsModalService.getRecipeTypeOptions();

    this.recipeFormulaDetails$ = this.recipeDetailsModalService.getRecipeFormulaDetails();
    this.recipeFormulaIngredients$ = this.recipeDetailsModalService.getRecipeFormulaIngredients();
    this.recipeFormulaSpectralChart$ = this.recipeDetailsModalService.getRecipeFormulaSpectralChartData();
    this.recipeFormulaAngles$ = this.recipeDetailsModalService.getRecipeFormulaAnglesData();
    this.recipePriceLists$ = this.recipeDetailsModalService.getRecipeFormulaPrices();
    this.linkedColorsDetails$ = this.recipeDetailsModalService.getLinkedColorData();
    this.colorCombinationDetails$ = this.recipeDetailsModalService.getColorCombinationDetails();

    this.noColorSystemSelectedWarning = this.recipeDetailsModalService.getNoColorSystemSelectedWarning();
    this.noRecipeSelectedWarning = this.recipeDetailsModalService.getNoRecipeSelectedWarning();
    this.noLinkedColorsWarning = this.recipeDetailsModalService.getNoLinkedColorsWarning();
    this.isTabDataError$ = this.recipeDetailsModalService.getIsTabDataError();
    this.defaultColorboxKey = +this.currentSettingsService.getDefaultColorboxKey() || 0;

    this.listenToRecipeDetailsTabsChanges();
    this.listenToTabChanges();
  }

  public listenToColorSystemFormChanges(): void {
    this.subscription = this.colorSystemFormControl.valueChanges
      .pipe(filter((colorSystemId: number | null) => !!this.recipeDetails && colorSystemId !== null))
      .subscribe((colorSystemId: number | null) => {
        if (colorSystemId === null) {
          return;
        }

        this.initFormulaId(colorSystemId);
        this.loadTabData(this.selectedTab);
      });
  }

  public hideModal(): void {
    const modalElement: HTMLElement | null = document.querySelector('ngb-modal-window.show');

    if (!modalElement) {
      return;
    }

    modalElement.classList.toggle('d-none', true);
  }

  public showModal(): void {
    const modalElement: HTMLElement | null = document.querySelector('ngb-modal-window.d-none');

    if (!modalElement) {
      return;
    }

    modalElement.classList.toggle('d-none', false);
  }

  public loadTabData(tab: RecipeDetailsTabModel | null): void {
    if (this.colorSystemFormControl.invalid || !tab || !this.recipeDetails?.id) {
      return;
    }

    this.isContentLoading = true;

    this.loadTabDataStrategy(tab);
  }

  public updateIsShownRecipeTypeToggle(tab: RecipeDetailsTabModel): void {
    const isNotIngredientsTab: boolean = tab.id !== RecipeDetailsTabsEnum.ingredients;
    const isNotPreviewTab: boolean = tab.id !== RecipeDetailsTabsEnum.preview;

    this.isShownRecipeTypeToggle = !!this.measurementId && isNotIngredientsTab && isNotPreviewTab;
  }

  public toggleCorrectionOptionState(isEnabled: boolean): void {
    this.isCorrection = isEnabled;

    if (isEnabled) {
      this.enableCorrectionOption();
    } else {
      this.disableCorrectionOption();
    }
  }

  protected listenToTabChanges(): void {
    this.subscription = this.recipeDetailsTabsService.activeTab$.subscribe((tab: RecipeDetailsTabModel) => {
      if (!tab) {
        return;
      }

      this.selectedTab = tab;
      this.updateIsShownRecipeTypeToggle(tab);
      this.loadTabData(tab);
    });
  }

  protected tryLoadDataAgain(): void {
    if (!this.selectedTab) {
      return;
    }

    this.recipeDetailsStateService.resetState();
    this.loadTabData(this.selectedTab);
  }

  protected initFormulaId(colorSystemId: number): void {
    this.formulaId = this.recipeDetailsStateService.getFormulaColorId(this.recipeDetails?.formulas, colorSystemId) || 0;
  }

  protected initializeColorSystems(recipe: RecipeDetailsModalParamsModel | null): void {
    if (!recipe?.formulas) {
      return;
    }

    this.subscription = this.recipeDetailsModalService.getFormulasColorSystems(recipe.formulas).subscribe({
      next: (colorSystems: Array<DropdownOptionModel<number>>) => {
        this.formulaColorOptions = colorSystems;
        this.colorSystemFormControl.reset();
        this.setDefaultColorSystem();
      },
    });
  }

  protected loadTabDataStrategy(tab: RecipeDetailsTabModel): void {
    if (!this.isInitRecipeDetailsInfoCalled && this.selectedTab?.id !== RecipeDetailsTabsEnum.info) {
      this.initRecipeDetailsInfo();
    }

    switch (tab.id) {
      case RecipeDetailsTabsEnum.prices:
        this.initRecipeDetailsPrices();
        break;
      case RecipeDetailsTabsEnum.ingredients:
        this.refreshRecipeFormulaIngredients();
        break;
      case RecipeDetailsTabsEnum.chart:
        this.initRecipeDetailsSpectralChart();
        break;
      case RecipeDetailsTabsEnum.preview:
        this.initRecipeDetailsPreview();
        break;
      case RecipeDetailsTabsEnum.linkedColor:
        this.initRecipeDetailsLinkedColors();
        break;
      case RecipeDetailsTabsEnum.colorCombinations:
        this.initRecipeDetailsColorCombinations();
        break;
      case RecipeDetailsTabsEnum.info:
      default:
        this.initRecipeDetailsInfo();
        break;
    }
  }

  protected initRecipeDetailsInfo(): void {
    if (!this.colorId || !this.formulaId) {
      return;
    }

    this.subscription = this.recipeDetailsModalService
      .initRecipeFormulaDetails(this.colorId, this.formulaId, this.recipeTypeForm.value, this.measurementId)
      .pipe(
        tap(() => (this.isInitRecipeDetailsInfoCalled = true)),
        finalize(() => {
          this.isContentLoading = false;
        })
      )
      .subscribe();
  }

  protected initRecipeDetailsPrices(): void {
    if (!this.formulaId) {
      return;
    }

    this.subscription = this.recipeDetailsModalService
      .initRecipeFormulaPrices(this.formulaId, this.recipePriceForm, this.recipeTypeForm.value, this.measurementId, this.recipeDetails?.id)
      .pipe(
        finalize(() => {
          this.isContentLoading = false;
        })
      )
      .subscribe();
  }

  protected refreshRecipeFormulaIngredients(): void {
    const isNotIngredientsTab: boolean = this.selectedTab?.id !== RecipeDetailsTabsEnum.ingredients;
    const isInvalidColorSystem: boolean = this.colorSystemFormControl.invalid;

    if (isNotIngredientsTab || isInvalidColorSystem || this.isCorrectionPreviousValue) {
      return;
    }

    this.isContentLoading = true;

    if (this.isCorrection && this.measurementId && this.recipeDetails?.id) {
      this.initRecipeDetailsCorrectionIngredients();

      return;
    }

    this.initRecipeDetailsIngredients();
  }

  protected initRecipeDetailsCorrectionIngredients(): void {
    if (!this.measurementId || !this.recipeDetails?.id) {
      return;
    }

    this.subscription = this.recipeDetailsModalService
      .initRecipeFormulaCorrectionIngredients(this.measurementId, this.recipeDetails.id)
      .pipe(
        finalize(() => {
          this.isContentLoading = false;
        })
      )
      .subscribe();
  }

  protected initRecipeDetailsIngredients(): void {
    this.subscription = this.recipeDetailsModalService
      .initRecipeFormulaIngredients(this.formulaId, this.recipeTypeForm.value, this.measurementId, this.recipeDetails?.id)
      .pipe(
        finalize(() => {
          this.isContentLoading = false;
        })
      )
      .subscribe();
  }

  protected initRecipeDetailsPreview(): void {
    if (!this.colorId || !this.formulaId || this.selectedTab?.id !== RecipeDetailsTabsEnum.preview) {
      return;
    }

    this.isContentLoading = true;

    this.subscription = this.recipeDetailsModalService
      .initRecipeFormulaAnglesData(
        this.colorId,
        this.formulaId,
        this.measurementId,
        this.anglesGeometriesIds,
        this.areAnglesForCorrectionDisplayed
      )
      .pipe(
        finalize(() => {
          this.isContentLoading = false;
        })
      )
      .subscribe();
  }

  protected initRecipeDetailsSpectralChart(): void {
    if (!this.colorId || !this.formulaId) {
      return;
    }

    this.subscription = this.recipeDetailsModalService
      .initRecipeFormulaSpectralChartData(
        this.recipeTypeForm.value,
        this.colorId,
        this.formulaId,
        this.measurementId,
        this.spectralChartGeometriesIds
      )
      .pipe(
        finalize(() => {
          this.isContentLoading = false;
        })
      )
      .subscribe();
  }

  private initRecipeDetailsLinkedColors(): void {
    if (!this.recipeDetails?.linkedColor?.isLinkedColor) {
      return;
    }

    this.subscription = this.recipeDetailsModalService
      .initLinkedColorDetails(
        this.recipeDetails.linkedColor.brandId,
        this.recipeDetails.linkedColor.colorId,
        this.colorSystemFormControl.value,
        this.formulaId
      )
      .pipe(
        finalize(() => {
          this.isContentLoading = false;
        })
      )
      .subscribe();
  }

  private initRecipeDetailsColorCombinations(): void {
    if (!this.recipeDetails?.combination?.isCombination) {
      return;
    }

    this.subscription = this.recipeDetailsModalService
      .initColorCombinationDetails(
        this.recipeDetails.combination.brandId,
        this.recipeDetails?.combination.colorId,
        this.colorSystemFormControl.value
      )
      .pipe(
        finalize(() => {
          this.isContentLoading = false;
        })
      )
      .subscribe();
  }

  private listenToRecipeDetailsTabsChanges(): void {
    this.subscription = this.recipeDetailsTabsService.getRecipeDetailsTabsAsObservable().subscribe({
      next: (tabs: Array<RecipeDetailsTabModel>) => {
        this.tabs = tabs;
      },
    });
  }

  private get showModalBeforeGoingToMixing(): boolean {
    return (
      this.isCorrection &&
      this.recipeTypeOptions.filter((typeOption: ToggleBoxOptionModel<RecipeTypeModel>) => !typeOption.isDisabled).length === 2
    );
  }

  private enableCorrectionOption(): void {
    this.recipeTypeOptions.map((option: ToggleBoxOptionModel<RecipeTypeModel>) => {
      if (option.value === RecipeTypeEnum.correction) {
        option.isDisabled = false;
      }

      return option;
    });
  }

  private disableCorrectionOption(): void {
    this.recipeTypeOptions.map((option: ToggleBoxOptionModel<RecipeTypeModel>) => {
      if (option.value === RecipeTypeEnum.correction) {
        option.isDisabled = true;
      }

      return option;
    });

    this.recipeTypeForm.setValue(RecipeTypeEnum.novolRecipe);
  }

  private setColorSystem(): void {
    if (this.formulaColorOptions.length !== 1) {
      return;
    }

    this.colorSystemFormControl.patchValue(this.formulaColorOptions[0].value, { emitEvent: false });
  }

  private getDefaultRecipePriceForm(): RecipePriceFormModel {
    return {
      amount: '100',
      unit: RecipesFormulaUnitsEnum.ml,
    };
  }

  private setDefaultColorSystem(): void {
    const filters: RecipesFiltersModel = this.route.snapshot.queryParams;

    if (!filters[RecipesFiltersEnum.colorSystem]) {
      this.setColorSystem();
      this.isColorSystemFormDisabled = false;

      return;
    }

    this.colorSystemFormControl.patchValue(Number(filters[RecipesFiltersEnum.colorSystem]));
    this.isColorSystemFormDisabled = true;
  }

  private setAreAnglesForCorrectionDisplayed(areAnglesForCorrectionDisplayed: boolean): void {
    this.areAnglesForCorrectionDisplayed = areAnglesForCorrectionDisplayed;
  }

  private setRecipeDetails(recipeDetails: RecipeDetailsModalParamsModel): void {
    this.recipeDetails = recipeDetails;
  }

  private setColorId(recipeDetails: RecipeDetailsModalParamsModel): void {
    this.colorId = this.getColorId(recipeDetails);
  }

  private getColorId(recipeDetails: RecipeDetailsModalParamsModel): number {
    if (recipeDetails?.combination?.isCombination) {
      return recipeDetails?.combination?.colorId || 0;
    }

    if (recipeDetails?.linkedColor?.isLinkedColor) {
      return recipeDetails?.linkedColor?.colorId || 0;
    }

    return recipeDetails?.colorId || 0;
  }

  private initRouteChangeListener(): void {
    this.subscription = this.router.events.pipe(filter((event: Event) => event instanceof NavigationStart)).subscribe(() => {
      this.modalService.dismissAll();
    });
  }
}
