import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { map, Observable } from 'rxjs';

import { ApiResponseDto } from '../../../core/dtos/api-response/api-response.dto';
import { ApiUrlUtil } from '../../../core/utils/api-url/api-url.util';
import { CombinationDetailsDto } from '../../dtos/combination-details/combination-details.dto';
import { CustomHttpParamEncoder } from '../../../core/encoders/custom-http-params-encoder/custom-http-params.encoder';
import { ListDto } from '../../../shared/dtos/list/list.dto';
import { CombinationAlternativesListDataPageDto } from '../../dtos/combination-alternatives-list-data-page/combination-alternatives-list-data-page.dto';
import { CombinationAlternativeListItemDto } from '../../dtos/combination-alternative-list-item/combination-alternative-list-item.dto';
import { DownloadFileDto } from '../../../shared/dtos/download-file/download-file.dto';
import { DownloadFileDtoCreator } from '../../../shared/creators/download-file-dto/download-file-dto.creator';

@Injectable({
  providedIn: 'root',
})
export class ColorCombinationsHttpService {
  constructor(private httpClient: HttpClient) {}

  public getCombinationDetails(brandId: number, colorId: number, colorSystemId: number): Observable<CombinationDetailsDto> {
    return this.httpClient
      .get<
        ApiResponseDto<CombinationDetailsDto>
      >(ApiUrlUtil.getApiUrl(`recipe/combinations/${brandId}/${colorId}/${colorSystemId}/details`))
      .pipe(map((response: ApiResponseDto<CombinationDetailsDto>) => response.data));
  }

  public getCombinationAlternativesList(
    id: string,
    colorId: number,
    combinationAlternativesListDataPage: CombinationAlternativesListDataPageDto
  ): Observable<ListDto<CombinationAlternativeListItemDto>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
      fromObject: { ...combinationAlternativesListDataPage },
    });

    return this.httpClient
      .get<
        ApiResponseDto<ListDto<CombinationAlternativeListItemDto>>
      >(ApiUrlUtil.getApiUrl(`recipe/combinations/${id}/${colorId}/alternatives`), { params })
      .pipe(map((response: ApiResponseDto<ListDto<CombinationAlternativeListItemDto>>) => response.data));
  }

  public exportCombinationToPdf(id: string): Observable<DownloadFileDto> {
    return this.httpClient
      .get<Blob>(ApiUrlUtil.getApiUrl(`recipe/combinations/${id}/pdf`), { observe: 'response', responseType: 'blob' as 'json' })
      .pipe(map((response: HttpResponse<Blob>) => DownloadFileDtoCreator.create(response)));
  }
}
