import { Component, Input } from '@angular/core';

import { BehaviorSubject, map, Observable, combineLatest } from 'rxjs';

import { RecipeDetailsTabModel } from '../../models/recipe-details-tab/recipe-details-tab.model';
import { NoFunctionalityService } from '../../../shared/services/no-functionality/no-functionality.service';
import { RecipeDetailsTabsService } from '../../services/recipe-details-tabs/recipe-details-tabs.service';
import { TabsWithPermissionComponent } from '../../../user-roles/components/tabs-with-permission/tabs-with-permission.component';
import { RecipeDetailsTabsEnum } from '../../enums/recipe-details-tabs/recipe-details-tabs.enum';

@Component({
  selector: 'app-recipe-details-tabs[isColorSystemFormValid]',
  templateUrl: './recipe-details-tabs.component.html',
  styleUrls: ['./recipe-details-tabs.component.scss'],
  standalone: false,
})
export class RecipeDetailsTabsComponent extends TabsWithPermissionComponent<RecipeDetailsTabModel> {
  @Input() public set isColorSystemFormValid(value: boolean) {
    this.isColorSystemFormValidSubject$.next(value);
  }

  @Input() public measurementId?: string;

  public activeTabId$: Observable<number>;
  public isColorSystemFormInvalid$: Observable<boolean>;

  private isColorSystemFormValidSubject$: BehaviorSubject<boolean>;

  constructor(
    noFunctionalityService: NoFunctionalityService,
    private recipeDetailsTabsService: RecipeDetailsTabsService
  ) {
    super(noFunctionalityService);

    this.isColorSystemFormValidSubject$ = new BehaviorSubject<boolean>(false);
    this.activeTabId$ = this.recipeDetailsTabsService.activeTab$.pipe(
      map((tab: RecipeDetailsTabModel) => tab?.id || RecipeDetailsTabsEnum.info)
    );
    this.isColorSystemFormInvalid$ = this.isColorSystemFormValidSubject$.pipe(map((isFormValid: boolean) => !isFormValid));
  }

  public override emitTabButtonClick(tab: RecipeDetailsTabModel): void {
    super.emitTabButtonClick(tab);
    this.recipeDetailsTabsService.setActiveTab(tab.id);
  }

  public getIsTabDisabled(tab: RecipeDetailsTabModel): boolean {
    return this.recipeDetailsTabsService.getIsTabDisabled(tab, this.measurementId);
  }

  public isTabActive$(tab: RecipeDetailsTabModel): Observable<boolean> {
    return combineLatest([this.activeTabId$, this.isColorSystemFormInvalid$]).pipe(
      map(([activeTabId, isInvalid]: [number, boolean]) => activeTabId === tab.id && !isInvalid)
    );
  }

  public isTabDisabled$(tab: RecipeDetailsTabModel): Observable<boolean> {
    return this.isColorSystemFormInvalid$.pipe(map((isInvalid: boolean) => isInvalid || this.getIsTabDisabled(tab)));
  }
}
