import { AddMixtureLayerFormModel } from '../../../mixes/models/add-mixture-layer-form/add-mixture-layer-form.model';
import { MixtureSourceTypeEnum } from '../../../mixes/enums/mixture-source-type/mixture-source-type.enum';
import { MixtureRecipeHistoryLayerDetailsModel } from '../../../mixes/models/mixture-recipe-history-layer-details/mixture-recipe-history-layer-details.model';
import { PrepareMixtureStateModel } from '../../../mixes/models/prepare-mixture-state/prepare-mixture-state.model';
import { AddMixtureLayerIngredientsFormModel } from '../../../mixes/models/add-mixture-layer-ingredients-data/add-mixture-layer-ingredients-data.model';
import { MixtureUnitsEnum } from '../../../mixes/enums/mixture-units/mixture-units.enum';

export class PrepareMixtureStateUtil {
  public static getPrepareMixtureStateFromMixtureDetails(
    mixtureDetails: MixtureRecipeHistoryLayerDetailsModel,
    measurementId?: string,
    searchFiltersSignature?: string,
    repairId?: string
  ): PrepareMixtureStateModel {
    return {
      createMixture: {
        source: {
          id: mixtureDetails.source.id,
          type: MixtureSourceTypeEnum.custom,
          originalType: mixtureDetails.source.originalType ?? mixtureDetails.source.type,
          measurementId,
          searchFiltersSignature,
        },
        layersNumber: mixtureDetails.layers.length,
        layers: mixtureDetails.layers.map((layer: AddMixtureLayerFormModel) => {
          layer.ingredients = layer.ingredients.map((ingredient: AddMixtureLayerIngredientsFormModel) => {
            return {
              ...ingredient,
              dosedAmount: 0,
              leftAmount: 0,
              recipeAmount: ingredient.dosedAmount.toString(),
            };
          });

          return layer;
        }),
        ...(repairId && { repairId }),
      },
      prepareMixtureConfigurationsFilters: {
        amount: mixtureDetails.amount,
        unit: MixtureUnitsEnum.g,
        isRfu: mixtureDetails.isRfu,
      },
    };
  }
}
