import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-recipe-details-mix-button',
  templateUrl: './recipe-details-mix-button.component.html',
  styleUrls: ['./recipe-details-mix-button.component.scss'],
  standalone: false,
})
export class RecipeDetailsMixButtonComponent {
  @Input() public isDisabled: boolean;

  @Output() public mixButtonClicked: EventEmitter<void>;

  constructor() {
    this.isDisabled = false;
    this.mixButtonClicked = new EventEmitter<void>();
  }

  public emitMixButtonClick(): void {
    this.mixButtonClicked.emit();
  }
}
