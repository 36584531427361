import { AfterViewChecked, Component, ElementRef, Input, ViewChild } from '@angular/core';

import { RecipeDetailsAngleModel } from '../../models/recipe-details-angles/recipe-details-angle.model';
import { RecipeDetailsAngleHexColorModel } from '../../models/recipe-details-angle-hex-color/recipe-details-angle-hex-color.model';

@Component({
  selector: 'app-recipe-details-angle-color-spectrum-preview',
  templateUrl: './recipe-details-angle-color-spectrum-preview.component.html',
  styleUrls: ['./recipe-details-angle-color-spectrum-preview.component.scss'],
  standalone: false,
})
export class RecipeDetailsAngleColorSpectrumPreviewComponent implements AfterViewChecked {
  @Input() public set angle(angleData: RecipeDetailsAngleModel) {
    if (!angleData) {
      return;
    }

    this.setAngleData(angleData);
  }
  @Input() public isLastItem?: boolean;

  @ViewChild('angleColorSpectrum') public angleColorSpectrumElement: ElementRef<HTMLElement> | null;

  public backgroundGradientStyle: string;
  public angleLabel: string;

  constructor() {
    this.angleColorSpectrumElement = null;
    this.backgroundGradientStyle = '';
    this.angleLabel = '';
  }

  public ngAfterViewChecked(): void {
    this.setChartColorSpectrumElementBackground();
  }

  private initBackgroundGradientStyle(angleData: RecipeDetailsAngleModel): void {
    this.backgroundGradientStyle = this.getLinearGradient(angleData);
  }

  private setChartColorSpectrumElementBackground(): void {
    if (!this.angleColorSpectrumElement?.nativeElement) {
      return;
    }

    const chartColorSpectrumElement: HTMLElement = this.angleColorSpectrumElement.nativeElement;
    chartColorSpectrumElement.style.backgroundImage = this.backgroundGradientStyle;
  }

  private setAngleData(angleData: RecipeDetailsAngleModel): void {
    this.angleLabel = angleData.name;

    this.initBackgroundGradientStyle(angleData);
  }

  private getLinearGradient(angleData: RecipeDetailsAngleModel): string {
    const gradientString: string = this.getGradientString(angleData);

    return `linear-gradient(${gradientString})`;
  }

  private getGradientString(angleData: RecipeDetailsAngleModel): string {
    const hexColors: Array<string> = this.getColors(angleData);
    const colorPercentPairs: Array<string> = this.getColorPercentPairs(hexColors);

    return colorPercentPairs.join(', ');
  }

  private getColors(angleData: RecipeDetailsAngleModel): Array<string> {
    const hexColorCodes: Array<string> = angleData.hexColors.map((color: RecipeDetailsAngleHexColorModel) => color.hexColor);

    if (hexColorCodes.length === 1) {
      return [...hexColorCodes, ...hexColorCodes];
    }

    return hexColorCodes;
  }

  private getColorPercentPairs(hexColors: Array<string>): Array<string> {
    return hexColors.map((colorHex: string, index: number) => {
      const rangePercentPointNumber: number = index * (100 / (hexColors.length - 1));

      return `${colorHex} ${rangePercentPointNumber}%`;
    });
  }
}
